*, *::before, *::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

html {
	height: 100%;
}

body {
	margin: 0;
	height: 100%;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.tg-main-button {
	position: absolute;
	width: 100%;
	bottom: 0;
	background-color: initial;
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;
	font-size: .9rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0.47rem 0.75rem;
	text-align: center;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	-webkit-user-select: none;
	user-select: none;
	vertical-align: middle;
}

.tg-body {
	height: 100%;
	max-width: 390px;
	position: relative;
	width: 100%;
}

::-webkit-scrollbar {
	display: none;
}

.tg-body-inner {
	background-color: var(--tg-theme-bg-color);
	color: var(--tg-theme-text-color);
	font-family: 'Roboto';
	padding: 0 25px;
	height: 100%;
}

.tg-header-single {
	text-align: center;
	margin-bottom: 15px;
	padding-top: 10px;
}

.tg-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	padding-top: 10px;
}

.tg-header-back {
	cursor: pointer;
	display: flex;
	align-items: center;
	flex: 1;
}

.tg-header-title {
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 26px;
	color: var(--tg-theme-text-color);
	text-align: center;
	flex: 2;
}

.tg-header-end {
	flex: 1;
	align-items: center;
	display: flex;
	justify-content: flex-end;
}

.tg-menu-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 15px 0;
	padding-bottom: 15px;
}

.tg-menu-item {
	display: flex;
	flex-direction: column;
	color: var(--tg-theme-text-color);
	font-size: 16px;
	line-height: 19px;
	cursor: pointer;
}

.tg-menu-item img {
	margin-bottom: 5px;
	border-radius: 10px;
	width: 155px;
	height: 87px;
	max-width: 100%;
	object-fit: contain;
}

.vertical-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tg-products-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	align-items: flex-start;
}

.tg-product-item-wrapper {
	margin-bottom: 5px;
}

.tg-product-item-img {
	border-radius: 10px;
	width: 97px;
	height: 87px;
	max-width: 100%;
	object-fit: contain;
}

.tg-products-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #000;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	position: relative;
}

.tg-products-item-quantity {
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 10px;
	font-size: 14px;
	width: 20px;
	height: 19px;
}

.tg-products-item-name {
	height: 38px;
	width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.tg-products-item-price {
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
}

.tg-products-button-add {
	border-radius: 10px;
	border: 1px solid transparent;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	-webkit-user-select: none;
	user-select: none;
	vertical-align: middle;
	height: 37px;
}

.tg-products-button-add2 {
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	border: 1px solid transparent;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	-webkit-user-select: none;
	user-select: none;
	padding: 0;
	width: 45px;
	height: 37px;
}

.tg-login-title {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: #222222;
	margin-bottom: 8px;
}

.tg-login-description {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #8E8E8E;
	margin-bottom: 20px;
}

.tg-cart-wrapper {
	display: flex;
	margin-bottom: 10px;
}

.tg-cart-title {
	font-size: 22px;
	font-weight: 700;
}

.tg-cart-img-wrapper {
	position: relative;
	text-align: center;
}

.tg-cart-price-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.tg-cart-item-name {
	font-size: 20px;
	padding-top: 10px;
	padding-left: 10px;
	text-align: start;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	height: 85px;
	overflow: hidden;
}

.tg-cart-item-price {
	padding-top: 10px;
	text-align: end;
}

.btn.disabled, .btn:disabled, .btn {
	opacity: .65;
	pointer-events: none;
}

@keyframes ripple {
	0% {
		width: 0;
		height: 0;
		opacity: 0.5;
	}

	100% {
		width: 50px;
		height: 50px;
		opacity: 0;
	}
}

.ripple:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0;
	height: 0;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background-color: #000;
	visibility: hidden;
	z-index: 2;
}

.ripple:not(:active):before {
	animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
	transition: visibility 0.4s step-end;
}

.ripple:active:before {
	visibility: visible;
}

.gelatine {
	animation: gelatine 0.5s infinite;
}

@keyframes gelatine {
	from, to {
		transform: scale(1, 1);
	}

	25% {
		transform: scale(0.9, 1.1);
	}

	50% {
		transform: scale(1.1, 0.9);
	}

	75% {
		transform: scale(0.95, 1.05);
	}
}

#loading-bg {
	top: 0;
	width: 390px;
	height: 100%;
	z-index: 9999;
	display: block;
	position: fixed;
}

.loading {
	position: absolute;
	left: calc(50% - 35px);
	top: 50%;
	z-index: 999;
	width: 55px;
	height: 55px;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 0 solid transparent;
}

.loading .effect-1,
.loading .effect-2 {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-left: 3px solid #5b73e8d9;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loading .effect-1 {
	animation: rotate 1s ease infinite;
}

.loading .effect-2 {
	animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-left: 3px solid #5b73e8d9;
	-webkit-animation: rotateOpacity 1s ease infinite 0.2s;
	animation: rotateOpacity 1s ease infinite 0.2s;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loading .effects {
	transition: all 0.3s ease;
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@keyframes rotateOpacity {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 0.1;
	}

	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
		opacity: 1;
	}
}

.radio-round {
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 50%;
	border: 1px solid #adb5bd;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	line-height: 44px;
	margin-right: 7px;
}

.form-check {
	display: flex;
	min-height: 1.35rem;
	margin-bottom: 0.125rem;
}

.form-check-input {
	width: 18px;
	height: 18px;
	margin: 0 10px 0 0;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid #adb5bd;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-check-input[type=radio] {
	border-radius: 50%;
}

.form-check-input:checked {
	background-color: #F8A91B;
	border-color: #F8A91B;
}

.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-label {
	font-size: 16px;
}

.fw-500 {
	font-weight: 500;
}

.hr {
	border: 1px solid #F4F4F4;
	margin-bottom: 3px;
	width: 90%;
}

/* BOOTSTRAP */
.d-none {
	display: none;
}

.w-40 {
	width: 40%;
}

.w-60 {
	width: 60%;
}

.w-100 {
	width: 100%;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.justify-content-start {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-between {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

label {
	color: #9EA09E;
	font-size: .8rem;
	font-weight: 700;
	margin-bottom: 0.2rem;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.input-group {
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.input-group-text {
	margin-bottom: 0;
}

.input-group-text {
	border: 1px solid #ced4da;
	border-radius: 0;
	align-items: center;
	background-color: #fff;
	color: #495057;
	display: flex;
	font-size: .9rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0.47rem 0.75rem;
	text-align: center;
	white-space: nowrap;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin-left: -1px;
}

.input-group>.form-control, .input-group>.form-select {
	flex: 1 1 auto;
	min-width: 0;
	position: relative;
	width: 1%;
}

.form-control {
	-webkit-appearance: none;
	appearance: none;
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	color: #495057;
	display: block;
	font-size: .96rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0.42rem 0.4rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	width: 100%;
	outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.btn {
	background-color: initial;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	color: #495057;
	cursor: pointer;
	display: inline-block;
	font-size: .9rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0.47rem 0.75rem;
	text-align: center;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	-webkit-user-select: none;
	user-select: none;
	vertical-align: middle;

	background-color: #5b73e8;
	border-color: #5b73e8;
	color: #fff;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.me-4 {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.custom-button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
}

.custom-button[disabled] {
	pointer-events: none;
	-webkit-filter: none;
	filter: none;
	opacity: 0.65;
}